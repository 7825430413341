import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import 'react-toastify/dist/ReactToastify.css';

import { postAxios } from '../../utils/api';
import { toast } from 'react-toastify';


const AddCategory = ({ open, handleClose }) => {
  const [formErrors, setFormErrors] = useState({});
  const [name, setName] = useState('');

  const [loading, setLoading] = useState(false);
 
 
  const submit = async (e) => {
    try {
      const errors = {};
      // Add similar validation for other fields
      if (!name.trim()) {
        setName('');
        errors.name = 'Name is required';
      }
      if (name.length < 3 || name?.length > 28) {
        errors.name = 'Category must between 3 to 28 characters long';
      }
      // If there are errors, set them in the state
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
      setLoading(true);
      await handleCreateNewMarket();
      setLoading(false);
      setName('');
      handleClose({fetch: true})
      toast.success('Category created successfully');

 
    } catch (err) {
        console.log(err, 'err');
        toast.error(err?.message || 'Something went wrong');
      setLoading(false);
    }
  };
  const handleCreateNewMarket = async () => {
    try {
      const data = {
        url: 'category/create',
        payload: {
          name,
          address: process.env.REACT_APP_CREATE_MARKET_ADDRESS
        },
      };
      await postAxios(data).then((response) => {
        
      });
    } catch (err) {}
  };

  const handleCloseModal = () => {
    setName('');
    handleClose({fetch: false});
  }

  

  return (
    <React.Fragment>
      <Dialog
        fullWidth={'sm'}
        open={open}
        // onClose={closeModalHandle}
        className='CreateMarketDialog_Wrapper'
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseModal();
          }
        }}
        disableBackdropClick
      >
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent className='marketModal_wrapper'>
          <Box className='marketModal_content'>
            <Box className='Type_Name'>
              <InputLabel>Name</InputLabel>
              <input
                className='type_Input'
                id='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant='outlined'
                placeholder='Enter Category Name'
                onBlur={() => {
                  if (formErrors.name) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      name: '', // Clear the error message for 'name'
                    }));
                  }
                }}
              />
              {formErrors.name && (
                <p className='error' style={{ color: 'red' }}>
                  {formErrors.name}
                </p>
              )}
            </Box>
        
          </Box>
        </DialogContent>
        <DialogActions>
          <Button  className='closeModal_Btn' onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            className='closeModal_Btn'
            disabled={loading}
            onClick={submit}
          >
            {loading ? 'loading' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AddCategory;
