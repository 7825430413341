import React, { useState, useEffect } from 'react';
import DashboardHeader from '../../components/DashboardHeader';

import { calculateRange } from '../../utils/table-pagination';


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAxios } from '../../utils/api';
import { Button, CircularProgress } from '@mui/material';
import AddCategory from './AddCategory';
import DeleteCategory from './DeleteCategory';
import { useDispatch } from 'react-redux';
import { getAllCategories } from '../../redux/actions/categories';

function Categories() {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDel, setOpenDel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [slicedData, setSlicedData] = useState([]);

    const sliceDataForCurrentPage = () => {
        const startIndex = (currentPage - 1) * recordsPerPage;
        const endIndex = startIndex + recordsPerPage;
        return categoriesList?.slice(startIndex, endIndex);
    };

    useEffect(() => {
        const sliced = sliceDataForCurrentPage();
        setSlicedData(sliced);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, categoriesList, recordsPerPage]);

    useEffect(() => {
        setPagination(calculateRange(categoriesList, recordsPerPage));
        fetchCategoriesList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPagination(calculateRange(categoriesList, recordsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriesList]);



    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = ({ fetch = true }) => {
        setOpen(false);
        fetch && fetchCategoriesList();
    };

    const handleOpenDeleteModal = () => {
        setOpenDel(true);
    };

    const handleCloseDeleteModal = ({ fetch = true }) => {
        setOpenDel(false);
        fetch && fetchCategoriesList();
    };


    const fetchCategoriesList = async () => {
        setLoading(true)
       const response = await dispatch(getAllCategories());
        if (response?.message) {
            setLoading(false);
            setCategoriesList([])
        } else {
            const sortedMarkets = response?.data?.sort((a, b) => {
                const dateA = new Date(a.created);
                const dateB = new Date(b.created);

                // Sorting in descending order
                return dateB - dateA;
            });
            setCategoriesList(sortedMarkets)
            setLoading(false);

        }

    };


    // Search
    const __handleSearch = (event) => {
        setSearch(event.target.value);
        if (event.target.value !== '') {
            let search_results = slicedData?.filter(
                (item) =>
                    item?.name?.toLowerCase().includes(search.toLowerCase())
            );
            setSlicedData(search_results);
        } else {
            resetSlicedData();
        }
    };

    const resetSlicedData = () => {
        setSlicedData(sliceDataForCurrentPage());
    };

    // Change Page
    const __handleChangePage = (new_page) => {
        setPage(new_page);
        setCurrentPage(new_page);
    };


    return (
        <div className='dashboard-content'>
            {/* <Modal show={modalShow} onHide={() => setModalShow(false)} /> */}
            <DeleteCategory
                open={openDel}
                handleClickOpen={handleOpenDeleteModal}
                handleClose={handleCloseDeleteModal}
            />
            <AddCategory
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
            />
            <DashboardHeader btnText='Create New' onClick={handleClickOpen} />

            <div className='dashboard-content-container'>
                <div className='dashboard-content-header'>
                    <h2>Categories</h2>
                    <div className='dashboard-content-search'>
                        <input
                            type='text'
                            value={search}
                            placeholder='Search by category'
                            className='dashboard-content-input'
                            onChange={(e) => __handleSearch(e)}
                        />
                    </div>
                </div>

                <table>
                    <thead>
                        <th>Sr.</th>
                        <th>CATEGORY</th>
                        <th>ACTIONS</th>
                    </thead>

                    {slicedData?.length !== 0 && !loading ? (
                        <tbody>
                            {slicedData?.map((category, index) => (
                                <tr key={`category_${index}`}>
                                    <td>
                                        <span>{index + 1 + (currentPage - 1) * recordsPerPage}</span>
                                    </td>
                                    <td>
                                        <span>{category?.name}</span>
                                    </td>
                                    <td>
                                        <Button
                                            className="delete-btn"
                                            data-id={category?._id}
                                            size="small"
                                            onClick={() => {
                                                setOpenDel(category?._id);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : null}
                </table>

                {loading ? <div className='dashboard-content-footer'>
                    <span className='empty-table'><CircularProgress /></span>
                </div> : slicedData?.length !== 0 ? (
                    <div className='dashboard-content-footer'>
                        {pagination?.map((item, index) => (
                            <span
                                key={index}
                                className={item === page ? 'active-pagination' : 'pagination'}
                                onClick={() => __handleChangePage(item)}
                            >
                                {item}
                            </span>
                        ))}
                    </div>
                ) : (
                    <div className='dashboard-content-footer'>
                        <span className='empty-table'>No data</span>
                    </div>
                )}


            </div>
        </div>
    );
}

export default Categories;
