// import * as React from "react";
import React, { forwardRef, useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, IconButton, Typography } from "@mui/material";
import "./LoginModal.css";
import AppleIcon from "@mui/icons-material/Apple";
import MetaMask from "../../assets/images/MetaMask.svg";
import CoinBase from "../../assets/images/coinBase.svg";
import ThirdCoin from "../../assets/images/thirdCoin.svg";
import Discord from "../../assets/images/discord.svg";
import X from "../../assets/images/X.svg";
import Facebook from "../../assets/images/Facebook.svg";
import CloseIcon from "@mui/icons-material/Close";

// import { gapi } from "gapi-script";
// const clientId =
//   "223011886225-lia8p82t7m42aq3csljr3u5u0893oiql.apps.googleusercontent.com";
// import EmailModal from "./EmailModal";
import { FcGoogle } from "react-icons/fc";
// import { ENV } from "../../config";
// import { ThemeContext } from "../../ThemeContext/Theme";
import { NavLink, useNavigate } from "react-router-dom";
import { handleRequest } from "../../utils/helpers";
import {
  handleSign,
  isMetaMaskInstalled,
  redirectToMetaMaskExtension,
  validateMetamask,
} from "../../contracts/web3";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { signInUser } from "../../redux/actions/users";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const icons = [
  {
    id: 1,
    image: MetaMask,
    alt: "metaMask",
    link: "/",
  },
  // {
  //   id: 2,
  //   image: CoinBase,
  //   alt: "coinBase",
  //   link: "/",
  // },
  // {
  //   id: 3,
  //   image: ThirdCoin,
  //   alt: "thirdCoin",
  //   link: "/",
  // },
  // {
  //   id: 4,
  //   image: X,
  //   link: "https://twitter.com/home",
  // },
  // {
  //   id: 5,
  //   image: Facebook,
  //   alt: "facebook",
  //   link: "https://www.facebook.com/",
  // },
  // {
  //   id: 6,
  //   image: Discord,
  //   alt: "Discord",
  //   link: "https://discord.com/",
  // },
];

const LoginModal = (props) => {
  //   const { theme } = useContext(ThemeContext);
  const { open, handleClose, modalType } = props;

  const [openEmail, setOpenEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isValidEmail = (email) => {
    // Implement your email validation logic here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const openEmailModal = () => {
    setOpenEmail(true);
  };

  const closeEmailModal = () => {
    setOpenEmail(false);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
  };

  //   useEffect(() => {
  //     function start() {
  //       gapi.auth2.init({
  //         client_id: clientId,
  //       });
  //     }
  //     gapi.load("client:auth2", start);
  //   });

  //   const onSuccess = (response) => {
  //     console.log("responseMessage", response);
  //   };
  //   const onFailure = (error) => {
  //     console.log("errorMessage", error);
  //   };
  //   const googleLogin = () => {
  //     window.open(`${ENV.serverUrl}/auth/google`, "_self");
  //   };
  const handleMetamask = () => {
    if (isMetaMaskInstalled()) {
      return validateUser();
    }
    redirectToMetaMaskExtension();
  };
  const validateUser = async () => {
    try {
      setLoading(true);
      // const currentChainId = await getChainId();
      // if (ENV.chainId !== currentChainId) {
      //   await switchNetworkToChainId(ENV.chainId);
      // }
      // const connectedAddress = await connectWithMetamask();
      // if (!connectedAddress) {
      //   return alert("address not foound");
      // }
      const connectedAddress = await validateMetamask();
      const res = await handleRequest("post", `/signUser/${connectedAddress}`);
      console.log(res, "res==>");
      const { users } = res.data;
      if (users?._id) {
        const { address, signature } = await handleSign(users?.nounce);
        loginUser(address, signature);
      } else {
        signUpUser(connectedAddress);
      }
    } catch (err) {
      setLoading(false);
      console.log(err, "error=>");
    }
  };

  const signUpUser = async (connectedAddress) => {
    try {
      const res = await handleRequest("post", `/register/${connectedAddress}`);
      if (res.data.success) {
        const { address, signature } = await handleSign(res.data.data.nounce);
        loginUser(address, signature);
      } else {
        setLoading(false);
        toast.error(res?.data?.message);
      }
    } catch (err) {
      console.log(err, "error while signing=>");
      setLoading(false);
    }
  };

  const loginUser = async (address, signature) => {
    try {
      const payload = {
        address,
        signature,
        role: "user",
      };
      dispatch(signInUser(payload, address, navigate));
    } catch (err) {
      console.log(err, "error while login");
      setLoading(false);
    }
  };
  return (
    <Box>
      <Box
        className="modal_wrapper"
        // open={open}
        // TransitionComponent={Transition}
        // keepMounted
        // onClose={handleClose}
        // aria-describedby="alert-dialog-slide-description"
      >
        <Box>
          <div className="mg_bottom">
            <Typography className="modal_title">
              Sign Up for Wagers World
            </Typography>
            <Typography
              // id="alert-dialog-slide-description"
              className="modal_subTitle"
            >
              Log in to your existing hugging face account to continue your
              journey.
            </Typography>
          </div>
          {/* <div className='formContent'>
            <Button
              className='google_btn'
            >
              <FcGoogle /> Continue with Google
            </Button>

            <Button className='apple_btn'>
              <AppleIcon />
              <Typography>Continue with Apple ID</Typography>
            </Button>

            <form>
              <input
                type='email'
                placeholder='Enter Email'
                onChange={handleEmailChange}
                value={email}
              />
              <Button
                className='continue_btn'
                onClick={openEmailModal}
                disabled={!isValidEmail(email)}
                style={{
                  backgroundColor: isValidEmail(email)
                    ? 'var(--header-listActiveColor)'
                    : '#706F6F',
                }}
              >
                Continue
              </Button>
            </form>
            <p className='OR'>OR</p>
          </div> */}
          <div className="icons_wrapper">
            {icons.map((content) => (
              <Button
                disabled={loading}
                className="icons_btns mg_bottom"
                onClick={() => handleMetamask()}
              >
                <img src={content.image} alt={content.alt} />
                {content.id === 1 ? (
                  <Typography>Continue with MetaMask</Typography>
                ) : (
                  ""
                )}
              </Button>
            ))}
          </div>
          <div className="privacy_line">
            <a href="/">Privacy Policy and Terms</a>
          </div>
        </Box>
      </Box>
      {/* <EmailModal
        openEmail={openEmail}
        openEmailModal={openEmailModal}
        closeEmailModal={closeEmailModal}
      /> */}
    </Box>
  );
};

export default LoginModal;
