import DashboardHeader from '../../components/DashboardHeader';
import Banner from '../../components/Settings/Banner';

export default function Settings() {
    return (
        <div className="dashboard-content">
            <DashboardHeader />
           <Banner />
        </div>
    )
}