import React, { useState, useEffect, useContext } from "react";
import DashboardHeader from "../../components/DashboardHeader";

import all_orders from "../../constants/orders";
import { calculateRange, sliceData } from "../../utils/table-pagination";

import "../styles.css";
import DoneIcon from "../../assets/icons/done.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import RefundedIcon from "../../assets/icons/refunded.svg";
import UpdateModal from "../../components/Modal/UpdateModal";
import CreateModal from "../../components/Modal/CreateModal";
import { Box, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAxios, postAxios } from "../../utils/api";
import ResolveModal from "../../components/Modal/ResolveModal";
import { resolveMarket } from "../../contracts/marketPlace/index";
import { useSelector, useDispatch } from "react-redux";
import { addTransaction } from "../../redux/actions/transaction";
import SocketContext from "../../context/socket";
import WithdrawProfitRow from "../../components/WithdrawProfit/WithdrawProfitRow";
import { getAllCategories } from "../../redux/actions/categories";
import TableData from "../../components/Table";
function EndedWagers() {
  const [search, setSearch] = useState("");
  const [orders, setOrders] = useState(all_orders);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const socket = useContext(SocketContext);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [slicedData, setSlicedData] = useState([]);

  const sliceDataForCurrentPage = () => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    return marketList?.slice(startIndex, endIndex);
  };

  useEffect(() => {
    const sliced = sliceDataForCurrentPage();
    // setSlicedData(sliced);
    formatData(sliced);
  }, [currentPage, marketList, recordsPerPage]);

  useEffect(() => {
    setPagination(calculateRange(marketList, recordsPerPage));
    fetchMarketList();
    dispatch(getAllCategories());
  }, []);

  useEffect(() => {
    setPagination(calculateRange(marketList, recordsPerPage));
  }, [marketList]);
  const formatItem = (item, i) => {
    const { typeName, category, question, image, winningOutCome ,outcomes,totalBets} =
      item;
    return [
      <span>{i + 1}</span>,
      <span>{typeName}</span>,
      <span>{category}</span>,
      <span>{question}</span>,
      <span>{"10%"}</span>,
      <span>{totalBets}</span>,


      <img
        src={image}
        className="dashboard-content-avatar"
        alt="Market Picture"
      />,
      <span>{`${outcomes[0]?.outcome1},${outcomes[1]?.outcome1}`}</span>,
      <span>{`${item?.winningOutCome||'-'}`}</span>,
    ];
  };

  const formatData = (data) => {
    const updatedData = data.map((item, i) => {
      return {
        data: formatItem(item, i),
      };
    });
    setSlicedData(updatedData);
  };

  useSelector((state) => console.log(state, "state==>"));
  const dispatch = useDispatch();

  // useEffect(() => {
  //   setPagination(calculateRange(marketList, 5));
  //   // setMarketList(sliceData(marketList, page, 5));
  //   fetchMarketList();
  // }, []);

  const fetchMarketList = async () => {
    const data = {
      url: "market/fetch_market_list",
      payload: {status:"resolved",
      },
    };
    await getAxios(data).then((response) => {
      if (response?.data?.error) {
        setMarketList([]);
      } else {
        const sortedMarkets = response?.data?.data?.sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);

          // Sorting in descending order
          return dateB - dateA;
        });
        console.log("sortedMarkets", sortedMarkets, response?.data?.data);

        const updatedMarket = sortedMarkets?.map((market) => {
          if (market?.bets?.length > 0) {
            const totalBets = market?.bets?.reduce(
              (accumulator, currentValue) => accumulator + currentValue.amount,
              0
            );
            return { ...market, totalAmountOfBets: totalBets };
            // console.log('updateMarket ====>', updateMarket)
          }
          return market;
        });
        console.log("updatedMarket", updatedMarket);

        setMarketList(sortedMarkets);
      }
    });
  };


  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== "") {
      let search_results = slicedData?.filter(
        (item) =>
          item?.question?.toLowerCase().includes(search.toLowerCase()) ||
          item?.slug?.toLowerCase().includes(search.toLowerCase()) ||
          item?.category?.toLowerCase().includes(search.toLowerCase())
      );
      formatData(search_results);
      // setSlicedData(search_results);
    } else {
      resetSlicedData();
    }
  };

  const resetSlicedData = () => {
    formatData(sliceDataForCurrentPage());
    // setSlicedData();
  };

  // Change Page
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setCurrentPage(new_page);
  };

  return (
    <div className="dashboard-content">
  
      <DashboardHeader/>

      <div className="dashboard-content-container">
        <div className="dashboard-content-header">
          <h2>Ended Wagers List</h2>
          <div className="dashboard-content-search">
            <input
              type="text"
              value={search}
              placeholder="Search with category or question"
              className="dashboard-content-input"
              onChange={(e) => __handleSearch(e)}
            />
          </div>
        </div>
        <TableData
          titles={[
            "ID",
            "TYPE NAME",
            "CATEGORY",
            "QUESTION",
            "PROFIT%",
            "MARKET TOTAL",
            "IMAGE",
            "OUTCOMES",
            "Winner",
          ]}
          data={slicedData}
          pagination={{ currentPage, recordsPerPage }}
        />

        {slicedData?.length !== 0 ? (
          <div className="dashboard-content-footer">
            {pagination.map((item, index) => (
              <span
                key={index}
                className={item === page ? "active-pagination" : "pagination"}
                onClick={() => __handleChangePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
        ) : (
          <div className="dashboard-content-footer">
            <span className="empty-table">No data</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default EndedWagers;
