import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import "./styles.css";

const SideBarItem = ({ item: Item, active, identity }) => {
  const [hover, setHover] = useState(false);
  return (
    <Link
      to={Item.path}
      className={active ? "sidebar-item-active" : "sidebar-item"}
    >
      {/* {identity === 1 ? (
        <DashboardIcon />
      ) : identity === 2 ? (
        <TrendingUpIcon />
      ) : (
        <PermIdentityIcon />
      )} */}
      {Item?.icon}
      <span className="sidebar-item-label">{Item.title}</span>
    </Link>
  );
};
export default SideBarItem;
