import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "react-toastify/dist/ReactToastify.css";

import { postAxios } from "../../utils/api";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";

const DeleteCategory = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false);

 
  const handleDeleteCategory = async () => {
    try {
    setLoading(true);
      const data = {
        url: "category/delete",
        payload: {
          id: open,
          address: process.env.REACT_APP_CREATE_MARKET_ADDRESS
        },
      };
      await postAxios(data);
      setLoading(false);
      handleClose({ fetch: true });
      toast.success("Category deleted successfully");

    } catch (err) {
        console.log(err, "err");
        toast.error(err?.message || "Something went wrong");
        setLoading(false);
    }
  };

  const handleCloseModal = () => {
    handleClose({ fetch: false });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={"sm"}
        open={open}
        className="CreateMarketDialog_Wrapper"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseModal();
          }
        }}
        disableBackdropClick
      >
        <DialogTitle>Delete Category</DialogTitle>
        <DialogContent className="marketModal_wrapper">
          <Box className="marketModal_content">
            <Box className="Type_Name">
              <Typography>Are you sure to delete this category?</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button className="closeModal_Btn" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            className="closeModal_Btn"
            disabled={loading}
            onClick={handleDeleteCategory}
          >
            {loading ? "loading" : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteCategory;
