import { combineReducers } from 'redux';
import transactionReducer from './transaction/index';
import userReducer from './users';
import categoriesReducer from './categories';

const rootReducer = combineReducers({
  transaction: transactionReducer,
  user: userReducer,
  category: categoriesReducer,
});

export default rootReducer;
