import { SERVER_ADDRESS } from "../../../utils/constants";
import { getRequest, postRequest } from "../../utils/request";
import { toast } from 'react-toastify';

export const updateBanner = (payload) => async() => {
    let url = SERVER_ADDRESS + `/banner`;
    return await postRequest(url, payload);
};

export const getBannerDetails = (payload) => async() => {
    let url = SERVER_ADDRESS + `/banner`;
    return await getRequest(url, payload);
};