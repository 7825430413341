import { getAxios } from "../../../utils/api";
import { GET_CATEGORIES } from "../../utils/constants";

export const getAllCategories = (payload) => async (dispatch) => {
    try {

        const data = {
            url: `category/getAll`,
            payload: {},
        };
        const res = await getAxios(data);
        dispatch({
            type: GET_CATEGORIES,
            payload: res?.data
        })
        return res;
    } catch (err) {
        return err
    }
}