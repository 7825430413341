import { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, CircularProgress } from "@mui/material";
import PicIcon from "@mui/icons-material/InsertPhotoOutlined";
import { useDispatch } from "react-redux";
import { updateBanner, getBannerDetails } from "../../redux/actions/banner";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import ColorPicker from "./ColorPicker";

export default function Banner() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const [showIcon, setShowIcon] = useState(false);
  const [image, setImage] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const [headingColor, setHeadingColor] = useState("#000000");
  const [subHeadingColor, setSubHeadingColor] = useState("#000000");

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const fileType = file.type;
    if (!["image/jpeg", "image/jpg", "image/png"].includes(fileType)) {
      return toast.error("Only image type of jpg, jpeg or png are allowed");
    }
    if (file.size / 1000 > 30000) {
      return toast.error("Max file size should be 30MB");
    }
    setSelectedImage(event.target.files[0]);
    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleSubmit = async () => {
    setLoading("submit");
    const formData = new FormData();
    selectedImage && formData.append("image", selectedImage);
    formData.append("title", heading);
    formData.append("subtitle", subHeading);
    formData.append("headingColor", headingColor);
    formData.append("subHeadingColor", subHeadingColor);
    formData.append("active", true);

    const res = await dispatch(updateBanner(formData));
    if (res) {
      toast.success("Settings updated successfully");
    }
    setLoading("");
  };

  const getData = async () => {
    setLoading("fetching");
    const res = await dispatch(getBannerDetails());
    if (res) {
      const data = res;
      setImage(data?.image);
      setHeading(data?.title);
      setSubHeading(data?.subtitle);
      setHeadingColor(data?.headingColor);
      setSubHeadingColor(data?.subHeadingColor);
    }
    setLoading("");
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (value?.length > 100) {
      toast.error("Max of 100 characters are allowed");
    }
    if (value?.trim()?.length <= 0) {
      return name === "heading" ? setHeading("") : setSubHeading("");
    }
    const maxVal = value?.length <= 100 ? value : value.slice(0, 100);
    name === "heading" ? setHeading(maxVal) : setSubHeading(maxVal);
  };

  return (
    <div className="dashboard-content-container">
      <div className="dashboard-content-header">
        {loading === "fetching" && <Loader loading={true} />}
        <Grid container>
          <Grid item xs={12} pb={1} pt={2}>
            <h2>Banner</h2>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Typography className="input-label">
              Banner Image
              <Typography component={"span"} color={"red"}>
                *
              </Typography>
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: 312,
                border: "1px solid #808080",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 2.5,
                backgroundColor: "#F7F7F7",
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              onMouseEnter={() => setShowIcon(true)}
              onMouseLeave={() => setShowIcon(false)}
            >
              <input
                type="file"
                id="upload-image"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              {((!selectedImage && !image) ||
                ((selectedImage || image) && showIcon)) && (
                <label htmlFor="upload-image">
                  <PicIcon sx={{ height: 168, width: 200, color: "#808080" }} />
                </label>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Typography className="input-label">Heading</Typography>
            <input
              className="input"
              type="text"
              value={heading}
              onChange={handleChange}
              placeholder="Banner Heading"
              name="heading"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <Typography className="input-label">Sub Heading </Typography>
            <input
              className="input"
              type="text"
              value={subHeading}
              onChange={handleChange}
              placeholder="Sub Heading"
              name="subHeading"
            />
          </Grid>
          <Grid item container xs={12} pt={2}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <ColorPicker
                label={`Heading Color`}
                color={headingColor}
                handleChange={(e) => setHeadingColor(e?.hex)}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <ColorPicker
                label={`Sub Heading Color`}
                color={subHeadingColor}
                handleChange={(e) => setSubHeadingColor(e?.hex)}
              />
            </Grid>
          </Grid>
          <Grid item md={12} lg={9} pt={3} pb={2}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{
                width: "100%",
                textTransform: "none",
                backgorund: "#3377DA",
                height: 50,
                fontWeight: 600,
                fontSize: 20,
              }}
              disabled={!image || loading}
            >
              {loading === "submit" ? <CircularProgress size={25} /> : "Save"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
