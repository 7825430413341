import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { Box, Typography } from "@mui/material";

const ColorPicker = ({label, color, handleChange}) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };



  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: color,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <Box>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
       <Typography className="input-label" pt={0} pb={0} mr={1}> {label}:</Typography>
        <Box style={styles.swatch} onClick={handleClick}>
          <Box style={styles.color} />
        </Box>
      </Box>
      {displayColorPicker ? (
        <Box>
          <Box style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </Box>
      ) : null}
    </Box>
  );
};

export default ColorPicker;
